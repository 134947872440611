import React, { useState } from "react";
import { Button, Card } from "react-bootstrap";

const DragAndDropFileUpload = ({
	selectedFile,
	setSelectedFile,
	handleUpload,
	isAudio = false
}) => {
	const [dragActive, setDragActive] = useState(false);
	const [fileTitle, setFileTitle] = useState("");

	const getBaseName = (filePath) => {
		return filePath.replace(/\.[^/.]+$/, "");
	};

	const handleFileSelect = (e) => {
		const file = e.target.files?.[0];
		if (file) {
			if (isAudio && !file.type.startsWith("audio/")) {
				alert("Please select a valid .mp3 file");
				return;
			}
			setSelectedFile(file);
			setFileTitle(getBaseName(file.name));
		}
	};

	const handleDragOver = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setDragActive(true);
	};

	const handleDragLeave = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setDragActive(false);
	};

	const handleDrop = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setDragActive(false);

		if (e.dataTransfer?.files?.length > 0) {
			const file = e.dataTransfer.files[0];
			if (isAudio && !file.type.startsWith("audio/")) {
				alert("Please drop a valid audio file");
				return;
			}
			setSelectedFile(file);
			setFileTitle(getBaseName(file.name));
			e.dataTransfer.clearData();
		}
	};

	const onUploadClick = () => {
		if (!selectedFile) return;
		handleUpload(selectedFile, fileTitle);
	};

	const cardTitle = isAudio ? "Upload Audio" : "Upload File";

	const acceptType = isAudio ? "audio/*" : "*";

	return (
		<Card>
			<Card.Header>
				<Card.Title>{cardTitle}</Card.Title>
			</Card.Header>
			<Card.Body>
				<div
					className={`border border-2 border-dashed p-4 text-center mb-3 ${
						dragActive ? "border-primary" : ""
					}`}
					style={{ cursor: "pointer" }}
					onDragOver={handleDragOver}
					onDragEnter={handleDragOver}
					onDragLeave={handleDragLeave}
					onDrop={handleDrop}
				>
					<input
						type="file"
						accept={acceptType}
						onChange={handleFileSelect}
						className="d-none"
						id="audio-upload"
					/>
					<label htmlFor="audio-upload" className="cursor-pointer">
						<i className="bi bi-cloud-upload fs-1"></i>
						<p className="mt-2">
							{dragActive
								? isAudio
									? "Drop your audio file here"
									: "Drop your file here"
								: isAudio
								? "Click or drag and drop your audio file here"
								: "Click or drag and drop files to upload"}
						</p>
					</label>
				</div>

				{selectedFile && (
					<div className="mb-3">
						<label className="form-label">File Title</label>
						<input
							type="text"
							className="form-control"
							value={fileTitle}
							onChange={(e) => setFileTitle(e.target.value)}
						/>
					</div>
				)}

				<Button
					variant="primary"
					onClick={onUploadClick}
					disabled={!selectedFile}
					className="w-100"
				>
					{selectedFile ? `Upload "${fileTitle}"` : "No File Selected"}
				</Button>
			</Card.Body>
		</Card>
	);
};

export default DragAndDropFileUpload;
