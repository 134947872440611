import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { Form, Button, Spinner, Modal } from "react-bootstrap";
import ToastError from "../../shared/elements/ToastError";

//import { getAuth, sendSignInLinkToEmail } from "firebase/auth";

const InviteUser = () => {
	const authContext = useContext(AuthContext);
	const { isLoading, error, sendRequest, clearError } = useHttpClient();
	const [email, setEmail] = useState("");
	const [firstName, setFirstName] = useState("");
	const [organizations, setOrganizations] = useState([]);
	const [avatarList, setAvatarList] = useState([]);
	const [selectedOrg, setSelectedOrg] = useState("");
	const [selectedAvatar, setSelectedAvatar] = useState("");
	const [showModal, setShowModal] = useState(false);
	const [inviteId, setInviteId] = useState("");
	const [errors, setErrors] = useState({});

	useEffect(() => {
		const fetchOrgs = async () => {
			try {
				const response = await sendRequest("/api/organizations", "GET", null, {
					Authorization: "Bearer " + authContext.token,
				});
				setOrganizations(response.data);
				const avatarResponse = await sendRequest("/api/avatars-list", "GET", null, {
					Authorization: "Bearer " + authContext.token,
				});
				setAvatarList(avatarResponse.data);
			} catch (err) {
				console.error("Error fetching organizations:", err);
			}
		};
		fetchOrgs();
	}, [sendRequest, authContext.token]);

	const validateForm = () => {
		let newErrors = {};

		if (!email.trim()) newErrors.email = "Email is required.";
		else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email))
			newErrors.email = "Invalid email format.";

		if (!firstName.trim()) newErrors.firstName = "First name is required.";
		if (!selectedOrg) newErrors.selectedOrg = "Organization is required.";

		setErrors(newErrors);
		return Object.keys(newErrors).length === 0;
	};

	const handleInviteUser = async () => {
		if (!validateForm()) return;

		try {
			const response = await sendRequest(
				`/api/invite`,
				"POST",
				JSON.stringify({ email, firstName, orgId: selectedOrg, avatarId: selectedAvatar }),
				{
					Authorization: "Bearer " + authContext.token,
					"Content-Type": "application/json",
				}
			);
			if (response.status === 200) {
				setInviteId(response.data.id);
				setShowModal(true);
				//setEmail("");
				setFirstName("");
				setSelectedOrg("");
				setSelectedAvatar("");
				setErrors({});
			}
		} catch (err) {
			console.error("Error inviting user:", err);
		}
	};

	return (
		<React.Fragment>
			<ToastError show={!!error} error={error} clearError={clearError} />
			{isLoading && <Spinner animation="border" />}
			<h2>Invite User</h2>
			<Form>
				<Form.Group controlId="email">
					<Form.Label>Email</Form.Label>
					<Form.Control
						type="email"
						placeholder="Enter email"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
						isInvalid={!!errors.email}
					/>
					<Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
				</Form.Group>
				<Form.Group controlId="firstName">
					<Form.Label>First Name</Form.Label>
					<Form.Control
						type="text"
						placeholder="Enter first name"
						value={firstName}
						onChange={(e) => setFirstName(e.target.value)}
						isInvalid={!!errors.firstName}
					/>
					<Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
				</Form.Group>
				<Form.Group controlId="organization">
					<Form.Label>Organization</Form.Label>
					<Form.Control
						as="select"
						value={selectedOrg}
						onChange={(e) => setSelectedOrg(e.target.value)}
						isInvalid={!!errors.selectedOrg}
					>
						<option value="">Select organization</option>
						{organizations.map((org) => (
							<option key={org.orgId} value={org.orgId}>
								{org.name}
							</option>
						))}
					</Form.Control>
					<Form.Control.Feedback type="invalid">{errors.selectedOrg}</Form.Control.Feedback>
				</Form.Group>
				<Form.Group controlId="avatarId">
					<Form.Label>Avatar ID (optional)</Form.Label>
					<Form.Control
						as = "select"
						value={selectedAvatar}
						onChange={(e) => setSelectedAvatar(e.target.value)}
					>
						<option value="">Select avatar</option>
						{avatarList.map((avatar) => (
							<option key={avatar.id} value={avatar.id}>
								{avatar.name}
							</option>
						))}
					</Form.Control>
				</Form.Group>
				<Button variant="primary" onClick={handleInviteUser}>
					Invite
				</Button>
			</Form>
			<Modal show={showModal} onHide={() => setShowModal(false)}>
				<Modal.Header closeButton>
					<Modal.Title>Invite Created!</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>
						An email has been sent to {email}! Share the following link with the user to complete the registration:
					</p>
					<p>
						<a href={`${window.location.origin}/invite?id=${inviteId}`}>
							{`${window.location.origin}/invite?id=${inviteId}`}
						</a>
					</p>
					<Button
						variant="primary"
						onClick={() =>
							navigator.clipboard.writeText(
								`${window.location.origin}/invite?id=${inviteId}`
							)
						}
					>
						Copy to Clipboard
					</Button>
				</Modal.Body>
			</Modal>
		</React.Fragment>
	);
};

export default InviteUser;
