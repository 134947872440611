import {
	useState,
	useEffect,
	useCallback,
	/* useRef,*/ useContext,
} from "react";
import { ArrowLeft /*MoreVertical*/ } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import VideoCard from "./VideoCard";
import VideoModal from "./VideoModal";
import {
	Toast,
	Spinner,
	OverlayTrigger,
	Tooltip,
	Button,
	Card,
	Form,
} from /*Spinner*/ "react-bootstrap";
import ShareButton from "./ShareButton";
import { FaDownload } from "react-icons/fa";
import DragAndDropFileUpload from "../components/DragAndDropFileUpload";
import FileCard from "./FileCard";
import useFolders from "../../shared/hooks/useFolders";

export default function FolderFiles() {
	const navigate = useNavigate();
	const { folderId } = useParams();
	const { sendRequest } = useHttpClient();
	const authContext = useContext(AuthContext);
	const [folder, setFolder] = useState(null);
	const [files, setFiles] = useState([]);
	const [showVideoModal, setShowVideoModal] = useState(false);
	const [selectedVideo, setSelectedVideo] = useState(null);
	const [showToast, setShowToast] = useState(false);
	const [toastMessage, setToastMessage] = useState("");
	const [isLoadingFiles, setIsLoadingFiles] = useState(true);
	const [isLoadingVideoDetails, setIsLoadingVideoDetails] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const [selectedOrgId, setSelectedOrgId] = useState("");
	const { uploadFile } = useFolders();
	const [userOrganizations, setUserOrganizations] = useState([]);
	const [showOrganizationDropdown, setShowOrganizationDropdown] =
		useState(false);
	const [organizationsLoaded, setOrganizationsLoaded] = useState(false);

	const fetchFolderDetails = useCallback(async () => {
		try {
			const response = await sendRequest(`/api/folders/${folderId}`);
			setFolder(response.data);
		} catch (err) {
			console.error("Error fetching folder details:", err);
		}
	}, [folderId, sendRequest]);

	//NEED TO HANDLE REFRESH FOLDER WHEN UPLOADING FILES
	//use setfiles below
	const fetchFiles = useCallback(async () => {
		setIsLoadingFiles(true);
		try {
			const response = await sendRequest(`/api/folders/${folderId}/files`);
			setFiles(response.data);
		} catch (err) {
			console.error("Error fetching files:", err);
		} finally {
			setIsLoadingFiles(false);
		}
	}, [folderId, sendRequest]);

	useEffect(() => {
		fetchFolderDetails();
		fetchFiles();
	}, [fetchFolderDetails, fetchFiles]);

	const handleShowVideo = async (video) => {
		setShowVideoModal(true);
		setSelectedVideo({
			id: video.id,
			title: video.title,
			thumbnail_url: video.thumbnail_url,
			video_url: video.video_url,
			status: video.status,
			shared: video.shared,
			public: video.public,
		});
		setIsLoadingVideoDetails(true);

		try {
			const response = await sendRequest(
				`/api/videos/${video.id}/details`,
				"GET",
				null,
				{
					Authorization: "Bearer " + authContext.token,
				}
			);
			setSelectedVideo(response.data);
		} catch (err) {
			console.error("Error fetching video details:", err);
		} finally {
			setIsLoadingVideoDetails(false);
		}
	};

	const handleDeleteVideo = async () => {
		if (!selectedVideo) return;
		try {
			await sendRequest(`/api/videos/${selectedVideo.id}`, "DELETE", null, {
				Authorization: "Bearer " + authContext.token,
			});
			setShowVideoModal(false);
			fetchFiles(); // Refresh the files list
		} catch (err) {
			console.error("Error deleting video:", err);
		}
	};

	const handleUnassignVideo = async (video) => {
		try {
			await sendRequest(
				`/api/folders/${folderId}/files/${video.id}`,
				"DELETE",
				null,
				{
					Authorization: "Bearer " + authContext.token,
				}
			);
			fetchFiles(); // Refresh the files list
			setToastMessage("Video unassigned successfully");
			setShowToast(true);
		} catch (err) {
			console.error("Error unassigning video:", err);
			setToastMessage("Failed to unassign video");
			setShowToast(true);
		}
	};

	const handleDeleteFile = async (fileId, folderId) => {
		try {
			await sendRequest(
				`/api/folders/${folderId}/files/${fileId}/delete`,
				"DELETE",
				null,
				{
					Authorization: "Bearer " + authContext.token,
				}
			);
			fetchFiles(); // Refresh the files list
			setToastMessage("File deleted successfully");
			setShowToast(true);
		} catch (err) {
			console.error("Error deleting file:", err);
			setToastMessage("Failed to delete file");
			setShowToast(true);
		}
	};


	const handleUpload = async (file, title) => {
		if (!file) return;
		try {
			await uploadFile(file, title, folderId, selectedOrgId);
			setSelectedFile(null);
			fetchFiles(); // Refresh the files list
		} catch (err) {
			console.error("Upload failed:", err);
		}
	};

	useEffect(() => {
		const fetchUserOrganizations = async () => {
			try {
				let organizations = [];
				if (authContext.isSuperAdmin) {
					const response = await sendRequest(
						"/api/organizations",
						"GET",
						null,
						{
							Authorization: "Bearer " + authContext.token,
						}
					);
					organizations = response.data;
				} else {
					// Regular users get memberships from authContext
					organizations = authContext.memberships;
				}

				setUserOrganizations(organizations);
				setShowOrganizationDropdown(
					authContext.isSuperAdmin || organizations.length > 1
				);
				setOrganizationsLoaded(true);
			} catch (err) {
				console.error("Error fetching organizations:", err);
			}
		};

		fetchUserOrganizations();
	}, [authContext, sendRequest]);

	useEffect(() => {
		if (organizationsLoaded && userOrganizations.length === 1) {
			setSelectedOrgId(userOrganizations[0].orgId);
		}
	}, [organizationsLoaded, userOrganizations]);

	const renderStatus = (status, videoUrl, publicShared) => {
		let statusElement;

		if (status === "pending") {
			statusElement = (
				<Spinner animation="border" size="sm" variant="warning" />
			);
		} else if (status === "processing") {
			statusElement = (
				<Spinner animation="border" size="sm" variant="success" />
			);
		} else if (status === "completed") {
			statusElement = (
				<div
					style={{
						display: "flex",
						alignItems: "center",
						justifyContent: "space-between",
						width: "100%",
					}}
				>
					<OverlayTrigger
						placement="top"
						delay={{ show: 250, hide: 400 }}
						overlay={
							<Tooltip>{status === "completed" ? "Download" : status}</Tooltip>
						}
					>
						<Button
							variant="link"
							style={{ padding: "5px", borderRadius: "50%" }}
							href={videoUrl}
							target="_blank"
							rel="noopener noreferrer"
							download
						>
							<FaDownload />
						</Button>
					</OverlayTrigger>
					{publicShared && (
						<ShareButton
							videoUrl={`${window.location.origin}/video?id=${selectedVideo.id}`}
							title={selectedVideo.title}
						/>
					)}
				</div>
			);
		} else if (status === "failed") {
			statusElement = (
				<span
					style={{
						display: "inline-block",
						width: "10px",
						height: "10px",
						backgroundColor: "red",
						borderRadius: "50%",
					}}
				></span>
			);
		}

		return <div style={{ display: "inline-block" }}>{statusElement}</div>;
	};

	/*const formatSize = (bytes) => {
		if (bytes === 0) return "0 Bytes";
		const k = 1024;
		const sizes = ["Bytes", "KB", "MB", "GB"];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
	};

	const formatDate = (timestamp) => {
		if (!timestamp) return "No date available";
		if (timestamp._seconds) {
			const date = new Date(timestamp._seconds * 1000);
			return date.toLocaleDateString("en-US", {
				month: "short",
				day: "numeric",
				year: "numeric",
			});
		}
		return new Date(timestamp).toLocaleDateString("en-US", {
			month: "short",
			day: "numeric",
			year: "numeric",
		});
	};*/

	return (
		<div className="min-h-screen bg-white">
			<style>
				{`
          .custom-scrollbar::-webkit-scrollbar {
            width: 8px;
          }
          .custom-scrollbar::-webkit-scrollbar-track {
            margin: 4px;
            background: transparent;
          }
          .custom-scrollbar::-webkit-scrollbar-thumb {
            background: rgb(209 213 219);
            border-radius: 9999px;
          }
          .custom-scrollbar::-webkit-scrollbar-thumb:hover {
            background: rgb(156 163 175);
          }
        `}
			</style>
			{/* Sticky header */}
			<div
				className="sticky top-0 bg-white z-10 border-b"
				style={{ padding: "1.5rem" }}
			>
				<div className="max-w-[98%] mx-auto">
					<div className="flex items-center justify-between">
						<div className="flex items-center space-x-4">
							<button
								onClick={() => navigate("/folders")}
								className="p-2 hover:bg-gray-100 rounded-full transition-colors"
							>
								<ArrowLeft className="w-6 h-6" />
							</button>
							<h1 className="text-2xl font-semibold">
								{isLoadingFiles ? "Loading..." : folder?.name || "Folder"}
							</h1>
						</div>
					</div>
				</div>
			</div>

			{/* Scrollable content */}
			<div
				className="overflow-y-auto"
				style={{
					height: "calc(100vh - 88px)",
					padding: "1.5rem",
					maxWidth: "98%",
					margin: "0 auto",
					"--scrollbar-thumb": "rgb(209 213 219)",
					"--scrollbar-thumb-hover": "rgb(156 163 175)",
					"--scrollbar-width": "8px",
					overflowY: "overlay",
					scrollbarWidth: "thin",
					scrollbarColor: "var(--scrollbar-thumb) transparent",
				}}
			>
				<style>
					{`
            div::-webkit-scrollbar {
              width: var(--scrollbar-width);
            }
            div::-webkit-scrollbar-track {
              background: transparent;
            }
            div::-webkit-scrollbar-thumb {
              background-color: var(--scrollbar-thumb);
              border-radius: 20px;
            }
            div::-webkit-scrollbar-thumb:hover {
              background-color: var(--scrollbar-thumb-hover);
            }
          `}
				</style>
				<div
					className="
                    grid grid-cols-1
                    md:grid-cols-2
                    lg:grid-cols-3
                    xl:grid-cols-4 gap-6"
				>
					{showOrganizationDropdown && (
						<Card className="mb-3">
							<Card.Header>
								<Card.Title>Select Organization</Card.Title>
							</Card.Header>
							<Card.Body>
								<Form.Group controlId="orgId">
									<Form.Label>Organization</Form.Label>
									<Form.Control
										as="select"
										value={selectedOrgId}
										onChange={(e) => setSelectedOrgId(e.target.value)}
									>
										<option value="">Select an organization</option>
										{userOrganizations.map((org) => (
											<option key={org.orgId} value={org.orgId}>
												{org.orgName || org.name}
											</option>
										))}
									</Form.Control>
								</Form.Group>
							</Card.Body>
						</Card>
					)}
					<DragAndDropFileUpload
						selectedFile={selectedFile}
						setSelectedFile={setSelectedFile}
						handleUpload={handleUpload}
					/>

					{isLoadingFiles ? (
						// Skeleton loading for video cards
						Array(10)
							.fill(0)
							.map((_, index) => (
								<div key={index}>
									<div className="bg-white rounded-lg shadow-sm border p-4">
										<div className="relative w-full pb-[56.25%] bg-gray-200 rounded-md mb-4 animate-pulse" />
										<div className="space-y-2">
											<div className="h-4 bg-gray-200 rounded w-3/4 animate-pulse" />
											<div className="h-3 bg-gray-200 rounded w-1/2 animate-pulse" />
										</div>
									</div>
								</div>
							))
					) : files.length === 0 ? (
						<div className="col-span-full text-center py-8">
							No files in this folder
						</div>
					) : (
						files.map((file) =>
							file.type === "video" ? (
								<VideoCard
									key={file.id}
									video={{
										id: file.videoId,
										videoId: file.videoId,
										title: file.name,
										thumbnail_url: file.thumbnail_url,
										video_url: file.url,
										status: "completed",
									}}
									onShowVideo={() =>
										handleShowVideo({
											id: file.videoId,
										})
									}
									showUnassignButton={true}
									onUnassign={() =>
										handleUnassignVideo({
											id: file.id,
										})
									}
								/>
							) : (
								<FileCard
									file={file}
									folderId={folderId}
									deleteFile={handleDeleteFile}
									key={file.id} 
									/>
							)
						)
					)}
				</div>
			</div>

			{showVideoModal && (
				<VideoModal
					show={showVideoModal}
					onHide={() => {
						setShowVideoModal(false);
						setSelectedVideo(null);
						setIsLoadingVideoDetails(false);
					}}
					video={selectedVideo}
					onDelete={handleDeleteVideo}
					renderStatus={renderStatus}
					isLoading={isLoadingVideoDetails}
				/>
			)}

			<Toast
				onClose={() => setShowToast(false)}
				show={showToast}
				delay={6000}
				autohide
				style={{ position: "fixed", top: 20, right: 20, zIndex: 9999 }}
			>
				<Toast.Header>{toastMessage}</Toast.Header>
			</Toast>
		</div>
	);
}
