import React, { useState } from "react";
import FileModal from "./FileModal";

function FileCard({ file, folderId, deleteFile }) {
	const [showModal, setShowModal] = useState(false);

	// A function to interpret the MIME type
	const isImage = file.type?.startsWith("image/");
	const isAudio = file.type?.startsWith("audio/");
	const isVideo = file.type?.startsWith("video/");

	// A function to render the card thumbnail/icon
	function renderCardPreview() {
		if (isImage) {
			// Show a small image as preview (thumbnail)
			return (
				<img
					src={file.url}
					alt={file.name}
					style={{ width: "100px", height: "auto" }}
				/>
			);
		} else if (isAudio) {
			// Show an audio icon
			return <i className="bi bi-file-earmark-music fs-1"></i>;
		} else if (isVideo) {
			// If you have a real thumbnail, show it. Otherwise, show a video icon
			return <i className="bi bi-file-earmark-play fs-1"></i>;
		} else {
			// Generic file icon
			return <i className="bi bi-file-earmark fs-1"></i>;
		}
	}

	// Renders full content in the modal
	function renderModalContent() {
		if (isImage) {
			return (
				<img
					src={file.url}
					alt={file.name}
					style={{ width: "100%", height: "auto" }}
				/>
			);
		} else if (isAudio) {
			return (
				<audio controls style={{ width: "100%" }}>
					<source src={file.url} type={file.type} />
					Your browser does not support the audio element.
				</audio>
			);
		} else if (isVideo) {
			return (
				<video controls style={{ width: "100%" }}>
					<source src={file.url} type={file.type} />
					Your browser does not support the video element.
				</video>
			);
		} else {
			return (
				<p>
					No preview available.
					<a href={file.url} target="_blank" rel="noopener noreferrer">
						Download
					</a>
				</p>
			);
		}
	}

	return (
		<>
			{/* The card */}
			<div
				className="p-4 border rounded-lg hover:shadow-md transition-shadow"
				onClick={() => setShowModal(true)}
				style={{ cursor: "pointer" }}
			>
				<div className="flex justify-between items-center">
					<span>{file.name}</span>
					{/* link to download */}
					<a
						href={file.url}
						target="_blank"
						rel="noopener noreferrer"
						className="text-blue-600 hover:text-blue-800"
						onClick={(e) => e.stopPropagation()} // prevent card click
					>
						Download
					</a>
				</div>
				<div className="mt-3">{renderCardPreview()}</div>
			</div>

			{/* The modal */}
			<FileModal
				show={showModal}
				onHide={() => setShowModal(false)}
				folderId={folderId}
				fileId={file.id}
				onDelete={deleteFile} // Your delete function
			>
				{renderModalContent()}
			</FileModal>
		</>
	);
}

export default FileCard;
