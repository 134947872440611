import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

function FileModal({ show, onHide, folderId, fileId, onDelete, children }) {
	const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

	const handleDeleteClick = () => {
		if (showDeleteConfirm) {
			onDelete(fileId, folderId);
			// onDelete is the callback to call your delete endpoint
		} else {
			setShowDeleteConfirm(true);
			setTimeout(() => {
				setShowDeleteConfirm(false);
			}, 3000);
		}
	};

	return (
		<Modal show={show} onHide={onHide} size="lg" centered>
			<Modal.Header closeButton>
				<Modal.Title>File Details</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{children /* e.g. your main content, preview, etc. */}

				<div className="mt-3 d-flex justify-content-end">
					<Button variant="danger" size="sm" onClick={handleDeleteClick}>
						{showDeleteConfirm
							? "Click again to confirm delete"
							: "Delete File"}
					</Button>
				</div>
			</Modal.Body>
		</Modal>
	);
}

export default FileModal;
